

.App {
    /* background-color: #24292e; */
    height: 100%;
    font-family: 'Raleway';
}
.App-header {
    background-color: white;
    min-height: 10vh;
    padding: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 2px solid rgb(0, 0, 0,0.9); */
    font-size: calc(10px + 2vmin);
    color: black;
  }

  .content {
    margin: 5px 20px 20px 20px;
    
  }

  /* Login Page styles ---------------------------------------------------------------------- */

  .login-background {
    height: 100vh;
    width: 100%;
    background: url('../src/Data/LoginBack.jpg') no-repeat center center fixed;
    background-size: cover;
}

  .login-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    width: 100%;
}


.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-size: 14px !important;
    margin-bottom: 5px;
}
.form-control {
    background-color: rgb(0, 0, 0, 0.7) !important;
    border-color: rgb(0, 0, 0, 0.7) !important;
    color: white !important;
}
.form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;

}

.login-btn-primary {
    border-radius: 18px !important;
    font-size: 18px;
    font-weight: 700;
    background-color: white !important;
    border-color:#134B70 !important;
    color: #134B70 !important;
  }

  .login-btn-primary:hover {
    color: white !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    background-color: #134B70 !important;
    border-color:#134B70 !important;

  }

  /* HomePage styles ------------------------------------------------------------------------ */

  .container-background {
    background: linear-gradient(45deg, 
        BLACK 0%,   /* Start with color 1 at 0% */
        #7BC9FF 100%  /* Transition to color 2 at 50% */
          /* End with color 3 at 100% */
    );
    padding: 5px 5px;
    border-radius: 12px;
  }

  .glass-container {
    background: rgba(0, 0, 0, 0.28); 
  /* box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.30);  */
  /* border-radius: 40px;  */
  backdrop-filter: blur(4px);
    display: flex;
    /* flex-direction: column; */
    padding: 16px 10px;
    border-radius: 12px;
    /* align-items: center; */
    /* justify-content: center; */
    max-width: 100% !important;
    font-size: calc(10px + 2vmin);
    color: white;
     box-shadow: 0 4px 8px rgba(0, 0, 0, 0  .2);
  }

  .title {
    font-size: 24px;
    align-items: center;
    align-content: center;
    font-weight: bold;
  }

  .upload-btn-primary {
    background-color: #C73659 !important;
    border-color: #C73659 !important;
    color: white !important;
  }

  .run-btn-primary {
    background-color: #2ecc71 !important;
    border-color:#B4B4B4 !important;
    color: white !important;
  }

  .run-btn-primary:hover {
    color: #000 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
    background-color: #2ecc71 !important;
    border-color:#2ecc71 !important;

  }

  .upload-btn-primary:hover{
    background-color: #A91D3A !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  }
  /* Ensure the parent containers and rows can stretch to full height */
.homepage {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content-row {
    /* height: calc(100vh - 28vh); */
     /* Subtract the height of the Header if necessary */
}



.options-container {
    /* display: flex;
    flex-direction: column; */
    background: rgb(0, 0, 0, 0.9); 
    /* box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.30);  */
    /* border-radius: 40px;  */
    backdrop-filter: blur(4px);
      display: flex;
      /* flex-direction: column; */
      padding: 16px 10px;
      border-radius: 12px;
      /* align-items: center; */
      /* justify-content: center; */
      max-width: 100% !important;
      font-size: calc(10px + 2vmin);
      color: white;
       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.player-container {
    height: 23.75%;
    /* display: flow-root !important; */
}

.breakdown-container {
    height: 100%;
    /* background: grey;  */
    border: 2px solid black;
    margin-bottom: 20px;
    /* box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.30);  */
    /* border-radius: 40px;  */
    backdrop-filter: blur(4px);
      display: flex;
      /* flex-direction: column; */
      padding: 20px 25px;
      border-radius: 12px;
      /* align-items: center; */
      /* justify-content: center; */
      max-width: 100% !important;
      font-size: calc(10px + 2vmin);
      color: black;
       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.grid-container {
    height: 100%;
}

/* Options styles */

.scrollable-list {
    /* max-height: 300px;  */
    /* Adjust height as needed */
    overflow-y: auto;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    padding: 10px;
}

.checkbox-item {
    margin-bottom: 10px;
}

.checkbox-item label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
}

.checkbox-item input[type="checkbox"] {
    margin-right: 10px; /* Adds space between the checkbox and the label text */
    height: 18px;
    width: 18px;    
    accent-color: #2ecc71;
    
}

.option-text {
    /* font-weight: bold; */
    padding-left: 10px;
    font-size: 24px;
    color: white;
    align-items: center;
}

.option-subtext {
    font-size: 16px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    margin-left: 27px;
}

/* Uploader styles  ------------------------------------------------------------------*/

.uploader-container {
    /* height: 20vh; */
    position: relative;
    z-index: 1000;
}

.uploader-input {
    /* display: flex; */
    width: 100%;
    align-items: center;
}

.input-group {
    width: 100% !important; 
    margin-right: 2%;
}

.or-divider {
    margin-right: 2%;
    margin-top: 8px;
}

.upload-button {
    margin-right: 2%;
    white-space: nowrap;
}

.uploaded-file-name {
    flex-grow: 1;
    margin-right: 2%;
    align-content: center;
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Handle long file names gracefully */
    font-size: 16px;
    text-align: left;
}

.dropdown-menu, .dropdown {
    z-index: 1050; /* Ensure the dropdown appears above other elements */
}
.run-button {
    margin-left: auto; /* Push to the extreme right */
}



/* Player styles */

.rhap_container {
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 100%;
    height: 100%;
    padding: 0px 0px !important; 
    background-color: transparent !important;
    box-shadow: 0 0px 0px transparent !important;
}

.rhap_main {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
    margin-top: 0px !important;
    order: -1;
}

.rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #B8030A !important;
    border-radius: 2px;
}

.rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    top: -8px;
    background: white !important;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

.rhap_main-controls-button {
    margin: 0 3px;
    color: white !important;
    font-size: 35px;
    width: 35px;
    height: 35px;
}

.rhap_additional-controls {
    display: none !important;
    flex: 1 0 auto;
    align-items: center;
}

.rhap_volume-controls {
    display: none !important;
    order: -1;
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
}

.rhap_controls-section {
    display: flex;
    flex: none !important;
    justify-content: space-between;
    align-items: center;
}

.rhap_volume-button {
    /* display: none; */


    flex: 0 0 26px;
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: white !important;
    margin-right: 6px;
}

.rhap_volume-bar {
    /* display: none; */


    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 4px;
    background: #B8030A !important;
    border-radius: 2px;
}

.rhap_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    left: 0;
    top: -4px;
    background: white !important;
    opacity: 0.9;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
    cursor: pointer;
}

.rhap_time {
    color: white !important;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
}

.rhap_repeat-button {
    display: none !important;
}





/* Breakdown styles ---------------------------------------------------------------------*/

.output-text {
    text-align: left;
    font-size: 18px;
}

/* .nav-tabs {
    border-bottom: 3px solid grey;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
    color: grey;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    cursor: pointer;
    color: grey;
}

.nav-tabs .nav-link:hover {
    color: #B8030A;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    border: 1px solid transparent !important;
}

.nav-tabs .nav-link.active {
    color: #495057;
    background-color: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 5px solid black !important;
} */

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}

.tab-heading {
    text-align: left;
    font-size: 20px;
    border-bottom: 2px solid black;
}
.active-tab-header {
    padding:  22px 10px 12px 10px;
}




/* Transcript styles --------------------------------------------------*/

.transcript-content {
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    /* padding: 10px 0px; */
    text-align: left;
    height: 77vh;
    overflow-y: auto;
}

.reduce-padding {
    padding-right: 0px !important;
}

.transcript-content p {
    margin: 0.5rem 0;
}

.transcript-content strong {
    color: #000;
    margin-top: 2rem;
}

.speech-rate-details {
    margin-top: 0.5rem;
    font-size: 0.9rem;
}

.speech-rate-details em {
   
    font-style: normal;
    font-weight: bold;
    color: #000;
}

.speaker-title{
    font-size: 1rem;
    font-weight: 800;
    text-align: left;
    color: black;
    white-space: nowrap;    
}

.transcript-content {
    /* padding: 20px; */
    font-family: Arial, sans-serif;
}

.transcript-item {
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding: 10px 10px;
    background-color: white;
    /* border-radius: 12px; */
    transition: all 0.7s ease-in-out;
    /* cursor: pointer; */
}

.transcript-item:hover {
    /* background-color: rgb(241, 241, 241); */
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
}

.highlight-background {
    background: linear-gradient(45deg, 
    rgb(187, 185, 185) 0%,   /* Start with color 1 at 0% */
    #D24545 100%  /* Transition to color 2 at 50% */
      /* End with color 3 at 100% */
);
border-radius: 12px;
}
.transcript-item.highlight {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.28); 
    color: white;
    transition: all 0.7s ease-in-out;
}

.current-time-highlight {
    background-color: #F1EFEF;
    color: black; /* Highlight color */
    padding: 5px 5px;
    border-radius: 7px;
    transition: background-color 0.3s ease;
}

.current-time-highlight.highlight {
    background-color: #efe8; /* Highlight color */
    padding: 5px 5px;
    border-radius: 7px;
    transition: background-color 0.3s ease;
}

.transcript-text{
    margin-left: 2px ;
}
.transcript-text:hover{
    cursor: pointer;
    background-color: #D1D8C5;
    color: black; /* Highlight color */
    padding: 5px 5px;
    border-radius: 7px;
    transition: background-color 0.3s ease;

}
.speaker-details {
    display: flex;
    
}


.speaker-title {
 
    font-weight: bold;
    margin-right: 5px;
}

.speaker-time {
    font-size: 0.9em;
    color: #666;
    text-align: left;
}

.speaker-time.highlight {
    color: white;
    transition: all 0.7s ease-in-out;
}
.speech-rate-details {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.speech-rate-details .icon {
    margin-right: 10px;
}

.speech-rate-details .details {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.details {
    padding-left: 50px;

    word-wrap: break-word;
}
.details-buttons {
    display: flex;
    gap: 10px;
}

.details-button {
    background-color: transparent;
    color: black;
    /* border: 1px solid black; */
    padding: 5px 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    transition: all 0.7s ease-in-out;
}

.details-button.highlight {
    /* border-color: white; */
     /* Change button background color */
    color: white; /* Change button text color */
    transition: all 0.7s ease-in-out;
}

/* .details-button:hover {
    background-color: #0056b3;
} */

.keywords {
    background-color: transparent;
    color: black;
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    display: inline-block;
    transition: all 0.7s ease-in-out;
}

.keywords.highlight {
    color: white;
    transition: all 0.7s ease-in-out; /* Change keywords text color */
}






/* Toggle Switch -------------------------------------------------------------*/

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin-left: 5px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 1px solid black;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    top: 2px;
    /* bottom: 2px; */
    background-color: black;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #AADDEE;
    
  }
  .ai-highlight-switch input:checked + .slider {
    background-color: #E97777; /* Your desired color */
}

  input:checked + .slider:before {
    transform: translateX(14px);
  }
  

/* Sequenmce of events --------------------------------------------------------------------------- */

.sequence-item {
    padding: 5px 10px;
    margin: 5px 0px;
}

.border-left-red {
    background-color: transparent;
    border-left: 10px solid red !important;
    box-shadow: 7px 0px 10px -6px rgba(211, 40, 40, 0.678);
}

.border-left-blue {
    border-left: 10px solid #60bdff !important;
    box-shadow: 7px 0px 10px -6px rgba(63, 217, 255, 0.89);
}

.border-left-green {
    border-left: 10px solid rgb(92, 233, 92) !important;
    box-shadow: 7px 0px 10px -6px rgb(97, 194, 52);
}

/* Sentiment styles --------------------------------------------------------------------------------*/

.sentiment-content {
    font-size: 1rem;
    line-height: 1.5;
    color: #333;
    padding: 10px 0px;
    text-align: left;
}

.sentiment-content p {
    margin: 0.5rem 0;
}

.sentiment-content strong {
    color: #000;
    margin-top: 2rem;
}

.sentiment-details {
    font-size: 1rem;
    margin-top: 0.5rem;
    text-align: left;
}

.sentiment-details em {

    font-style: normal;
    font-weight: bold;
    color: #000;
}


.accordion-item {
    color: var(--bs-accordion-color);
    background-color: none !important;
    border: none !important;
    /* border-left: 1px solid red; */
}


.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    font-weight:800;
    background-color: transparent !important;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none !important;
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) 10px;
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}

.accordion-body {
    padding: 5px var(--bs-accordion-body-padding-x);
}

.accordion-header {
    padding-left: 10px;
}
.accordion-item:first-of-type>.accordion-header .accordion-button {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}


/* Trend Line Styles -------------------------------------------------------------------------------- */

.tabs {
    cursor: pointer;
    margin-bottom: 20px;
    /* border-bottom: 1px solid black !important; */
  }
  
  .tabs button {
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    background: transparent;
    margin-right: 2px;
    transition: backgound 0.3s ease;
  }
  
  .tabs button:hover {
    background: #ddd;
  }
  
  .tabs button.active {
    border-bottom: 2px solid black;
    font-weight: bold;
  }
  
  .tab-content {
    padding: 20px;
    /* height: 60vh; */
  }
  
/* Topic Detection------------------------------------------------------------ */
.no-bullets {
    list-style-type: none;
    padding: 0;
  }


  /* Audio events styles ------------------------------------------------------ */

  .audioevents-content {
    padding: 10px 10px;
    font-size: 1.2rem;
  }

  /* .table {
    border-radius: 12px !important;

    font-size: 18px;
    margin-bottom: 0px !important;
  }
  .table-bordered {
    border: 2px solid black !important;
    border-radius: 10px;
    overflow: hidden;
}

.table-bordered thead th {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #92C7CF; 
}

.table-bordered tbody tr:first-child {
    background-color: #92C7CF; 
}

.table-bordered tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.table-bordered tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
 */



/* Utterances ----------------------------------------------------------------------- */

.table .first-column {
    width: 40%;
}

.error {
    color: red !important;
}

.default {
    color: inherit;
}
